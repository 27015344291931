import React, { useContext } from "react";
import {Link, useParams} from "react-router-dom";
import Markdown from "markdown-to-jsx";
import {blogData} from "../../data/blogData";
import {ThemeContext} from "../../../../ThemeProvider/ThemeProvider";

// This is stupid because markdown-to-jsx won't work without being hardcoded, so I gotta do it like this
const BlogPost = (props) => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-white" : "text-gray-800";

    const { slug } = useParams(); // Extract the slug from the URL

    const blog = blogData.find((blog) => blog.slug === slug);

    // Customize this code block override as desired
    const codeBlockOverride = {
        component: (props) => (
            <pre
                style={{
                    backgroundColor: "#f7fafc",
                    borderRadius: "0.5rem",
                    padding: "1rem",
                    overflowX: "scroll",
                    // Customize this line to change the font family for code blocks
                    fontFamily: "monospace",
                    // Customize this line to change the font size for code blocks
                    fontSize: "1rem",
                    // Customize this line to change the line height for code blocks
                    lineHeight: "1.5",
                    // Customize this line to change the margin for code blocks
                    margin: "1rem 0",
                    // Customize this line to change the color for the code text
                    color: "#333",
                }}
                {...props}>
                {props.children}
            </pre>
        ),
    };

    return (
        <section className="relative pb-8">
            <div className="md:px-4">
                <div className={`py-6 px-4 md:mx-4 ${isDarkMode ? "bg-gray-900" : "bg-white"} rounded-md shadow-md`}>
                    <div className="mx-8">
                        <Link to="/blog" className="text-blue-500 mb-4 inline-block">
                            &lt; Go Back
                        </Link>
                        <h1 className={`${boldColor} text-3xl font-bold mb-4`}>{blog.title}</h1>
                        <p className="text-gray-400 text-sm mb-4">{blog.date}</p>
                        <Markdown
                            options={{
                                overrides: {
                                    img: {
                                        component: (props) => (
                                            <img
                                                alt=""
                                                style={{
                                                    maxWidth: "100%",
                                                    height: "auto",
                                                    marginBottom: "1rem",
                                                }}
                                                {...props}
                                            />
                                        ),
                                    },
                                    a: {
                                        component: ({children, href}) => (
                                            <a href={href} style={{
                                                marginBottom: "1rem",
                                                textDecoration: 'underline',
                                                color: 'royalblue',
                                            }}
                                               rel="noreferrer noopener"
                                               target="_blank"
                                            >
                                                {children}
                                            </a>
                                        ),
                                    },
                                    p: {
                                        component: (props) => (
                                            <p style={{
                                                marginBottom: "1rem",
                                                color: "gray" // Add custom text color here
                                            }} {...props}>
                                                {props.children}
                                            </p>
                                        ),
                                    },
                                    pre: codeBlockOverride,
                                    code: {
                                        component: (props) => (
                                            <code
                                                style={{
                                                    // Customize this line to change the background color for inline code blocks
                                                    backgroundColor: "#f7fafc",
                                                    padding: "0.25rem 0.5rem",
                                                    borderRadius: "0.25rem",
                                                    // Customize this line to change the font family for inline code blocks
                                                    fontFamily: "monospace",
                                                    // Customize this line to change the font size for inline code blocks
                                                    fontSize: "0.9rem",
                                                    // Customize this line to change the color for the code text
                                                    color: "#333",
                                                }}
                                                {...props}>
                                                {props.children}
                                            </code>
                                        ),
                                    },
                                    h1: {
                                        component: (props) => (
                                            <h1
                                                className={`${boldColor}`}
                                                style={{
                                                    fontSize: "3rem",
                                                    fontWeight: "bold",
                                                    marginBottom: "1rem",
                                                    color: isDarkMode ? "white" : "black",
                                                }}
                                                {...props}>
                                                {props.children}
                                            </h1>
                                        ),
                                    },
                                    h2: {
                                        component: (props) => (
                                            <h2
                                                className={`${boldColor}`}
                                                style={{
                                                    fontSize: "2.5rem",
                                                    fontWeight: "bold",
                                                    marginBottom: "0.5rem",
                                                    color: isDarkMode ? "white" : "black",
                                                }}
                                                {...props}>
                                                {props.children}
                                            </h2>
                                        ),
                                    },
                                    h3: {
                                        component: (props) => (
                                            <h3
                                                className={`${boldColor}`}
                                                style={{
                                                    fontSize: "2rem",
                                                    fontWeight: "bold",
                                                    marginBottom: "0.5rem",
                                                    color: isDarkMode ? "white" : "black",
                                                }}
                                                {...props}>
                                                {props.children}
                                            </h3>
                                        ),
                                    },
                                    h4: {
                                        component: (props) => (
                                            <h4
                                                className={`${boldColor}`}
                                                style={{
                                                    fontSize: "1.5rem",
                                                    fontWeight: "bold",
                                                    marginBottom: "0.5rem",
                                                    color: isDarkMode ? "white" : "black",
                                                }}
                                                {...props}>
                                                {props.children}
                                            </h4>
                                        ),
                                    },

                                    h5: {
                                        component: (props) => (
                                            <h5 style={{
                                                fontSize: "1.25rem",
                                                fontWeight: "bold",
                                                marginBottom: "0.5rem",
                                                color: isDarkMode ? "white" : "black",
                                            }}
                                                {...props}>
                                                {props.children}
                                            </h5>
                                        ),
                                    },
                                    h6: {
                                        component: (props) => (
                                            <h6 style={{
                                                fontSize: "1rem",
                                                fontWeight: "bold",
                                                marginBottom: "0.5rem",
                                                color: isDarkMode ? "white" : "black",
                                            }}
                                                {...props}>
                                                {props.children}
                                            </h6>
                                        ),
                                    },
                                    ul: {
                                        component: (props) => (
                                            <ul style={{
                                                color: "gray",
                                                listStyleType: "disc",
                                                marginLeft: "1.5rem",
                                                marginBottom: "1rem"
                                            }}
                                                {...props}>
                                                {props.children}
                                            </ul>
                                        ),
                                    },
                                    ol: {
                                        component: (props) => (
                                            <ol style={{
                                                color: "gray",
                                                listStyleType: "decimal",
                                                marginLeft: "1.5rem",
                                                marginBottom: "1rem"
                                            }}
                                                {...props}>
                                                {props.children}
                                            </ol>
                                        ),
                                    },
                                },
                            }}
                        >
                            {blog.content}
                        </Markdown>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogPost;