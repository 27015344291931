import React, { useContext } from "react";

import Skills from "./TechSkill/Skill";
import Experience from "./Experience/Experience";
import Education from "./Education/Education";
import Knowledge from "./Knowledge/Knowledges";
import Summary from "./Summary/Summary";
import SoftSkill from "./SoftSkill/SoftSkills"
import {ThemeContext} from "../../../ThemeProvider/ThemeProvider";

const About = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";

    return (
        <section className="relative py-8">
            <div className="flex flex-wrap md:px-4">
                <div className="w-full">
                    <div className="md:mx-4">
                        <h3 className={`${boldColor} text-2xl font-medium mb-4`}>
                            Summary
                        </h3>
                        <Summary />
                    </div>
                    <div className="md:mx-4">
                        <h3 className={`${boldColor} text-2xl font-medium mb-4`}>Experience</h3>
                    </div>
                    <Experience />
                    <div className="md:mx-4">
                        <h3 className={`${boldColor} text-2xl font-medium mb-4`}>Education</h3>
                    </div>
                    <Education />
                    <div className="md:mx-4">
                        <h3 className={`${boldColor} text-2xl font-medium mb-4`}>Technical Skills</h3>
                    </div>
                    <Knowledge />
                    <div className="md:mx-4">
                        <h3 className={`${boldColor} text-2xl font-medium mb-4`}>Soft Skills</h3>
                    </div>
                    <SoftSkill />
                </div>
            </div>
        </section>
    );
};

export default About;