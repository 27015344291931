import React, {useContext} from "react";
import {
    HashRouter as Router,
    NavLink,
    Route,
    Switch,
} from "react-router-dom";

import ScrollToTop from "react-router-scroll-top";

import {ThemeContext} from "../../../ThemeProvider/ThemeProvider";
import About from "../../pages/About/About";
import Skills from "../../pages/Resume/About";
import Webdev from "../../pages/Portfolio/About";
import Blog from "../../pages/Blog/About";
import Contact from "../../pages/Contact/Contact";
import BlogPost from "../../pages/Blog/Blog/BlogPost";

import {FaSun, FaMoon} from "react-icons/fa";

const navbarData = [
    {
        id: 1,
        title: "About",
        to: "/about",
    },
    {
        id: 2,
        title: "Portfolio",
        to: "/portfolio",
    },
    {
        id: 3,
        title: "Resume",
        to: "/resume",
    },
    {
        id: 4,
        title: "Contact",
        to: "/contact",
    },
    {
        id: 5,
        title: "Blogs",
        to: "/blog",
    },
];

const Navbar = () => {
    const {isDarkMode, toggleDarkMode} = useContext(ThemeContext);

    const bgColor = isDarkMode ? "bg-gray-900" : "bg-white";
    const textColor = isDarkMode ? "text-gray-100" : "text-gray-700";

    return (
        <Router>
            <ScrollToTop>
                <nav className={`${bgColor} md:mx-8 mb-3 px-6 py-2 z-10 sticky top-0 shadow rounded`}>
                    <div className="flex justify-between items-center">
                        <button
                            className={`${textColor} flex items-center rounded-md p-2 focus:outline-none hover:bg-gray-700 hover:text-gray-100 transition-colors duration-200`}
                            onClick={toggleDarkMode}
                        >
                            {isDarkMode ? (
                                <>
                                <span className="bg-blue-500 rounded-full p-2 mr-3">
                                    <FaSun className="text-white w-4 h-4"/>
                               </span>
                                    <span className="ml-1">Light Mode</span>
                                </>
                            ) : (
                                <>
                                 <span className="bg-blue-500 rounded-full p-2 mr-3">
                                    <FaMoon className="text-white w-4 h-4"/>
                                 </span>
                                    <span className="ml-1">Dark Mode</span>
                                </>
                            )}
                        </button>
                    </div>
                    <ul className={`justify-center flex flex-wrap ${textColor}`}>
                        {navbarData.map((el, id) => (
                            <LinkItem el={el} key={id} textColor={textColor}/>
                        ))}
                    </ul>
                </nav>

                <Switch>
                    <Route exact path="/">
                        <About/>
                    </Route>
                    <Route path="/about">
                        <About/>
                    </Route>
                    <Route path="/resume">
                        <Skills/>
                    </Route>
                    <Route path="/portfolio">
                        <Webdev/>
                    </Route>
                    <Route exact path="/blog" component={Blog}/>
                    <Route path="/contact">
                        <Contact/>
                    </Route>
                    <Route path="/blog/:slug" component={BlogPost}/>
                </Switch>
            </ScrollToTop>
        </Router>
    );
};

const LinkItem = (props) => {
    const {title, to, textColor} = props.el;
    return (
        <li className="m-2 lg:mx-5">
            <NavLink
                className={`hover:text-yellow-300 transition ease-out duration-200 ${textColor}`}
                activeClassName="text-blue-600"
                to={to}
            >
                {title}
            </NavLink>
        </li>
    );
};

export default Navbar;