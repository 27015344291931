import React, { useContext, useState, useEffect } from "react";
import {
    FaGithub,
    FaLinkedinIn,
    FaTwitter,
    FaDeviantart,
    FaDiscord,
    FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaStepBackward, FaStepForward
} from "react-icons/fa";
import { SiRoblox, SiYoutube, SiInstagram } from "react-icons/si";
import picture from "../../../images/unnamed.jpg";
import resume from "../../../Resume/SingharajUsai2024Resume.pdf";
import { ThemeContext } from "../../../ThemeProvider/ThemeProvider";


const socials = [
    {
        id: 1,
        icon: <FaGithub />,
        link: "https://github.com/singharaj-usai",
        bgColor: "bg-gray-700", // Specify the background color here
        hoverColor: "hover:bg-gray-500", // Specify the hover color here
    },
    {
        id: 2,
        icon: <FaLinkedinIn />,
        link: "https://www.linkedin.com/in/singharaj-usai",
        bgColor: "bg-blue-600", // Specify the background color here
        hoverColor: "hover:bg-blue-400", // Specify the hover color here
    },
    {
        id: 3,
        icon: <SiYoutube />,
        link: "https://www.youtube.com/channel/UCPtsPXxpdPSLBy-dIe2oONQ",
        bgColor: "bg-red-500",
        hoverColor: "hover:bg-red-300",
    },
    {
        id: 3,
        icon: <SiInstagram />,
        link: "https://www.instagram.com/singharajusai",
        bgColor: "bg-pink-500", // Specify the background color here
        hoverColor: "hover:bg-pink-300", // Specify the hover color here
    },
    {
        id: 4,
        icon: <FaTwitter />,
        link: "https://twitter.com/OMrbobbilly",
        bgColor: "bg-blue-400", // Specify the background color here
        hoverColor: "hover:bg-blue-200", // Specify the hover color here
    },
    {
        id: 5,
        icon: <FaDeviantart />,
        link: "https://deviantart.com/realmrbobbilly",
        bgColor: "bg-green-500", // Specify the background color here
        hoverColor: "hover:bg-green-300", // Specify the hover color here
    },


    {
        id: 6,
        icon: <SiRoblox />,
        link: "https://www.roblox.com/users/446187905/profile",
        bgColor: "bg-gray-500", // Specify the background color here
        hoverColor: "hover:bg-gray-300", // Specify the hover color here
    },
];



const Card = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
    const textColor = isDarkMode ? "text-white" : "text-gray-400"
    const iconColor = "text-white"; // Update iconColor to always be white

    return (
        <aside className={`sticky top-0 z-10 ${isDarkMode ? "bg-gray-900" : "bg-white"} 
        md:mx-8 lg:mx-4 mb-8 p-6 
        shadow rounded-md -mt-40
        transform transition-all duration-300`}>
        
        {/* Profile Image */}
        <div className="relative w-24 h-24 mx-auto mb-5">
            <div className="absolute inset-0 bg-blue-500 rounded-xl transform rotate-6 transition-transform group-hover:rotate-12"></div>
            <div className="relative overflow-hidden rounded-xl ring-4 ring-blue-500 ring-opacity-40">
                <img 
                    src={picture} 
                    alt="Profile" 
                    className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-110" 
                />
            </div>
        </div>

        {/* Name and Title */}
        <div className="text-center">
            <h1 className={`text-2xl md:text-3xl font-bold mb-2 ${boldColor}
                transform transition-all duration-300 hover:text-blue-500`}>
                Singharaj Usai
            </h1>
            <p className="text-blue-500 font-medium mb-6">
                Full-stack Web Developer
            </p>

            {/* Resume Button */}
            <div className="mb-6">
                <a href={resume}
                    className="inline-flex items-center px-6 py-2.5 
                    bg-blue-500 text-white rounded-full font-medium
                    transform transition-all duration-300
                    hover:bg-blue-300 hover:-translate-y-0.5
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    target="_blank"
                    rel="noreferrer">
                    <span>View Resume</span>
                </a>
            </div>

            {/* Social Icons */}
            <div className="flex justify-center space-x-3 mb-8">
                {socials.map((social, id) => (
                    <a
                        key={id}
                        href={social.link}
                        target="_blank"
                        rel="noreferrer"
                        className={`${social.bgColor} ${social.hoverColor} 
                            p-2 rounded-full transform transition-all duration-300
                            hover:-translate-y-1 hover:shadow-lg`}
                    >
                        <span className="text-white text-lg">
                            {social.icon}
                        </span>
                    </a>
                ))}
            </div>
        </div>

        {/* About Section */}
        <div className={`pt-6 border-t ${isDarkMode ? "border-gray-800" : "border-gray-200"}`}>
            <h3 className={`text-lg font-semibold mb-3 ${boldColor}`}>
                Welcome
            </h3>
            <div className={`${textColor} text-sm leading-relaxed space-y-2`}>
                <p>
                    Hello! My name is{" "}
                    <span className="text-yellow-500 font-medium">Singharaj Usai</span>,
                    welcome to my personal website!
                </p>
                <p>
                    Read about my background, view my{" "}
                    <span className="text-green-500 font-medium">Portfolio</span> page 
                    where I showcase public projects, read my{" "}
                    <a href={resume} 
                       className="text-blue-500 hover:text-blue-600 underline"
                       target="_blank" 
                       rel="noreferrer">
                        resume
                    </a>
                    , find out ways to contact me, and read occasional blog posts.
                </p>
            </div>
        </div>
    </aside>
);
};

const SocialIcon = (props) => {
    const {icon, link, bgColor, hoverColor} = props.social;
    return (
        <li className="m-2">
            <a
                title="sociallinks"
                target="_blank"
                rel="noopener noreferrer"
                href={link}
                className={`w-8 h-8 rounded-full text-white flex items-center justify-center ${bgColor} ${hoverColor} transition ease-out duration-200`}
            >
                {icon}
            </a>
        </li>
    );
};

export default Card;

