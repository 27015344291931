import React, { useState, useEffect, useContext } from "react";
import image1 from "../../../../images/MadCity.png";
import image2 from "../../../../images/star wars battleground kylo ren.png";
import image3 from "../../../../images/Santa escape.png";
import image4 from "../../../../images/pet sim x.png";
import image5 from "../../../../images/pokemon winter.jpg";
import image6 from "../../../../images/roblox_meep_city.png";
import image7 from "../../../../images/rich man thumbnail.png";
import image8 from "../../../../images/Roblox Jailbreak Thumbnail.png";
import image9 from "../../../../images/roblox_level_up_by_realmrbobbilly_dfsvzve.png";
import image10 from "../../../../images/roblox_obunga_thumbnail_by_realmrbobbilly_dfsvkye.png";
import image11 from "../../../../images/he_gave_me_mewtwo__notclickbait__pokemon_brick_bro_by_realmrbobbilly_deer8bc.jpg";
import image12 from "../../../../images/i_bought_mewtwo_in_pokemon_brick_bronze_notclickba_by_realmrbobbilly_deer8cw.jpg";
import image13 from "../../../../images/i_unlocked_the_8th_gym_notclickbait_pokemon_brick__by_realmrbobbilly_deer8d6.jpg";
import image14 from "../../../../images/how_to_duplicate_pokemon_in_brick_bronze___roblox__by_realmrbobbilly_deer8bo.jpg";
import image15 from "../../../../images/Roblox-Ramps.png";
import image16 from "../../../../images/roblox-abc-lore.png";
import GFXItem from "./GFXItem";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";
import Pagination from "react-paginate";

const NUMBER_ITEMS_PER_PAGE = 2;

const gfxData = [
    {
        id: 1,
        image: image16,
        toptitle: "2023",
        title: "Roblox ABC Lore",
        link: "https://roblox.com",
        description: "put description here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-ABC-Lore-987185047",
        btntype1: "View on Deviantart",
    },
    {
        id: 1,
        image: image15,
        toptitle: "2023",
        title: "Roblox Cart Ride Funny Moments",
        link: "https://roblox.com",
        description: "put description here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Cart-Ride-986408659",
        btntype1: "View on Deviantart",
    },
    {
        id: 2,
        image: image9,
        toptitle: "2023",
        title: "Roblox Level Up",
        link: "https://roblox.com",
        description: "put description here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Level-Up-955514714",
        btntype1: "View on Deviantart",
    },
    {
        id: 3,
        image: image10,
        toptitle: "2023",
        title: "Roblox Doors",
        link: "https://roblox.com",
        description: "put description here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Obunga-Thumbnail-955495382",
        btntype1: "View on Deviantart",
    },
    {
        id: 4,
        image: image1,
        toptitle: "2022",
        title: "Roblox Mad City",
        link: "https://roblox.com",
        description: "put description here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Mad-City-Background-Thumbnail-933083277",
        btntype1: "View on Deviantart",
    },
    {
        id: 5,
        image: image2,
        toptitle: "2022",
        title: "Star Wars Battleground",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Star-Wars-Battlefront-Thumbnail-923968560",
        btntype1: "View on DeviantArt",
    },
    {
        id: 6,
        image: image3,
        toptitle: "2022",
        title: "Santa Escape",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Santa-Escape-922244027",
        btntype1: "View on DeviantArt",
    },
    {
        id: 7,
        image: image4,
        toptitle: "2022",
        title: "Pet Sim X",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Pet-Simulator-X-922174575",
        btntype1: "View on DeviantArt",
    },
    {
        id: 8,
        image: image5,
        toptitle: "2022",
        title: "Pokemon Brick Bronze",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Pokemon-Christmas-922119085",
        btntype1: "View on DeviantArt",
    },
    {
        id: 9,
        image: image6,
        toptitle: "2022",
        title: "Meep City",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Meep-City-922112198",
        btntype1: "View on DeviantArt",
    },
    {
        id: 10,
        image: image7,
        toptitle: "2022",
        title: "Roblox Rich Man",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Rich-Man-Thumbnail-921917837",
        btntype1: "View on DeviantArt",
    },
    {
        id: 11,
        image: image8,
        toptitle: "2022",
        title: "Roblox Jailbreak",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly/art/Roblox-Jailbreak-Thumbnail-919297230",
        btntype1: "View on DeviantArt",
    },
    {
        id: 12,
        image: image11,
        toptitle: "2017",
        title: "HE GAVE ME MEWTWO *not clickbait* Brick Bronze",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly",
        btntype1: "View on DeviantArt",
    },
    {
        id: 12,
        image: image12,
        toptitle: "2017",
        title: "I BOUGHT MEWTWO IN BRICK BRONZE *notclickbait*",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly",
        btntype1: "View on DeviantArt",
    },
    {
        id: 13,
        image: image13,
        toptitle: "2017",
        title: "I UNLOCKED THE 8TH GYM IN BRICK BRONZE *notclickbait*",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly",
        btntype1: "View on DeviantArt",
    },
    {
        id: 14,
        image: image14,
        toptitle: "2017",
        title: "HOW TO DUPLICATE POKEMON IN BRICK BRONZE",
        link: "https://roblox.com",
        description: "put decription here",
        btnlink1: "https://www.deviantart.com/realmrbobbilly",
        btntype1: "View on DeviantArt",
    },

    // more items here
];

const Gfx = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberItemsPerPage, setNumberItemsPerPage] = useState(NUMBER_ITEMS_PER_PAGE);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    }
    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia("(min-width: 425px)").matches) {
                setNumberItemsPerPage(2);
            } else {
                setNumberItemsPerPage(1);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const prevNextTextColor = isDarkMode ? "text-white" : "text-gray-600";
    const pageNumberTextColor = isDarkMode ? "text-gray-50" : "text-gray-800";
    const activePageNumberTextColor = "text-white";

    return (
        <section className="pb-4">
            <div className="flex justify-center flex-wrap">
                {gfxData
                    .slice(currentPage * numberItemsPerPage, (currentPage + 1) * numberItemsPerPage)
                    .map((gfx, id) => (
                    <GFXItem gfx={gfx} key={id}/>
                ))}
            </div>
            <Pagination
                className="flex justify-center"
                pageCount={Math.ceil(gfxData.length / numberItemsPerPage)}
                initialPage={currentPage}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                previousLabel={
                    <span className={`relative px-3 py-1 font-medium rounded-md ${prevNextTextColor}`}>Previous</span>
                }
                nextLabel={
                    <span className={`relative px-3 py-1 font-medium rounded-md ${prevNextTextColor}`}>Next</span>
                }
                breakLabel={<span className="relative px-3 py-1 font-medium text-gray-700 rounded-md">...</span>}
                pageClassName="mx-2"
                breakClassName="relative font-medium text-gray-700 rounded-md"
                pageLinkClassName={`px-2 py-1 rounded-md text-sm ${pageNumberTextColor}`} // Apply text color to page number buttons
                previousLinkClassName="px-2 py-1 rounded-md text-sm"
                nextLinkClassName="px-2 py-1 rounded-md text-sm"
                activeClassName={`bg-blue-500 ${activePageNumberTextColor}`} // Apply white text color to active page number
            />
        </section>
    );
};

export default Gfx;