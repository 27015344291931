import React, { useContext } from "react";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";

const KnowledgeItems = (props) => {
    const { isDarkMode } = useContext(ThemeContext);
    const { title, icon } = props.knowledge;

    return (
        <div className={`${isDarkMode ? "bg-gray-900" : "bg-white"} 
            rounded-lg shadow-sm hover:shadow-md
            transform transition-all duration-300 hover:-translate-y-1
            border ${isDarkMode ? "border-gray-800" : "border-gray-100"}
            ${isDarkMode ? "hover:bg-gray-800" : "hover:bg-blue-50/50"}`}>
            <div className={`p-3 flex items-center space-x-3
                ${isDarkMode ? "text-gray-300" : "text-gray-600"}`}>
                <div className="text-xl flex-shrink-0">
                    {icon}
                </div>
                <span className="font-medium text-sm">
                    {title}
                </span>
            </div>
        </div>
    );
};

export default KnowledgeItems;