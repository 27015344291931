import React, { useContext } from "react";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";
import { BsCheckCircleFill } from "react-icons/bs";

const SoftSkillsItems = (props) => {
    const { isDarkMode } = useContext(ThemeContext);
    const { title } = props.softskills;

    return (
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
            <div className={`${isDarkMode ? "bg-gray-900" : "bg-white"} 
                rounded-lg shadow-sm hover:shadow-md
                transform transition-all duration-300 hover:-translate-y-1
                border ${isDarkMode ? "border-gray-800" : "border-gray-100"}
                ${isDarkMode ? "hover:bg-gray-800" : "hover:bg-yellow-50"}`}>
                <div className={`px-4 py-3 flex items-center justify-center space-x-2
                    ${isDarkMode ? "text-gray-300" : "text-gray-600"}`}>
                    <span className="font-medium text-sm">{title}</span>
                </div>
            </div>
        </div>
    );
};

export default SoftSkillsItems;