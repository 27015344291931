import React, { useContext, useRef, useState, useEffect } from "react";
import { FaGithub, FaExternalLinkAlt, FaPlay } from "react-icons/fa";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";

const WebdevItem = (props) => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
    const textColor = isDarkMode ? "text-white" : "text-gray-500";
    const buttonColor = isDarkMode
        ? "bg-gray-700 hover:bg-gray-600 transition ease-out duration-200"
        : "bg-white hover:bg-gray-100 transition ease-out duration-200";

    const [isTransitioning, setIsTransitioning] = useState(false);
    const videoRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        if (props.webdev.id === 0) {
            const transitionTimeout = setTimeout(() => {
                setIsTransitioning(true);
            }, 9000);

            return () => clearTimeout(transitionTimeout);
        }
    }, [props.webdev.id]);

    // Check if video or image exist
    const hasVideo = props.webdev.video !== undefined && props.webdev.video !== "";
    const hasImage = props.webdev.image !== undefined && props.webdev.image !== "";

    // Conditionally render the image and video
    const imageOrVideo = (
        <>
            {hasImage && !isTransitioning && (
                <img
                    ref={imageRef}
                    src={props.webdev.image}
                    alt={props.webdev.title}
                    className={`w-full h-full object-cover object-center transition-opacity duration-1000 ${
                        isTransitioning && hasImage ? "opacity-0" : "opacity-100"
                    } ${hasImage ? "visible" : "invisible"}`}
                />
            )}
            {hasVideo && (isTransitioning || !hasImage) && (
                <video
                    ref={videoRef}
                    className={`w-full h-full object-cover transition-opacity duration-1000 ${
                        isTransitioning && hasVideo ? "opacity-100" : "opacity-0"
                    } ${hasVideo ? "visible" : "invisible"}`}
                    controls

                >
                    <source src={props.webdev.video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
        </>
    );

    return (
        <div className="w-full lg:w-1/2 mb-8">
            <div className={`${isDarkMode ? "bg-gray-900" : "bg-white"} 
                md:mx-4 p-6 shadow rounded-xl 
                transform transition-all duration-300
                hover:shadow-xl hover:-translate-y-1
                overflow-hidden flex flex-col h-full`}>
                <div className="mb-4 overflow-hidden rounded-xl">
                    {imageOrVideo}
                </div>
                <div className="flex-grow">
                    <div className={`mb-2 ${boldColor} font-medium text-xl`}>{props.webdev.title}</div>
                    <p className="text-sm text-gray-400">{props.webdev.description}</p>
                </div>
                <div className="flex justify-center flex-wrap">
                    {props.webdev.technologies && (
                        <ul className="mx-2 mt-4 flex flex-wrap mt-2">
                            {props.webdev.technologies.map((tech, index) => (
                                <li key={index} className="text-sm mb-2 mr-2">
                                    <button className={`outline border-blue-500 border-2 ${textColor} py-1 px-3 rounded-md hover:bg-blue-500 hover:outline-none hover:text-white transition ease-out duration-200`}>
                                        {tech}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="flex justify-center flex-wrap">
                    {props.webdev.btnlink1 && (
                        <a className="mx-2 mt-4 inline-flex items-center rounded-full bg-blue-500 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-blue-300 transition ease-out duration-200"
                           target="_blank"
                           href={props.webdev.btnlink1}
                           rel="noreferrer"
                        >
                            <FaExternalLinkAlt className="w-4 h-4 mr-2" />
                            {props.webdev.btntype1}
                        </a>
                    )}
                    {props.webdev.btnlink2 && (
                        <a
                            className={`shadow-md mx-2 mt-4 inline-flex items-center rounded-full ${buttonColor} text-center border-0 py-2 px-6 ${textColor} leading-7 tracking-wide`}
                            target="_blank"
                            href={props.webdev.btnlink2}
                            rel="noreferrer"
                        >
                            <FaGithub className="w-4 h-4 mr-2" />
                            {props.webdev.btntype2}
                        </a>
                    )}
                    {props.webdev.btnlink3 && (
                        <div className="mx-2 mt-4 inline-block rounded-full bg-blue-300 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide">
                            {props.webdev.btntype3}
                        </div>
                    )}
                    {props.webdev.btnlink4 && (
                        <a className="mx-2 mt-4 inline-flex items-center rounded-full bg-blue-500 text-center border-0 py-2 px-14 text-white leading-7 tracking-wide hover:bg-blue-300 transition ease-out duration-200"
                           target="_blank"
                           href={props.webdev.btnlink4}
                           rel="noreferrer"
                        >
                            <FaPlay className="w-4 h-4 mr-2"/>
                            {props.webdev.btntype4}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WebdevItem;
