import React from "react";
import EducationItem from "./EducationItem";

import edxbootcamp from "../../../../images/edx-boot-camps.png"

const educationData = [
    {
        id: 4,
        schoolpic: "https://www.samaritas.org/portals/0/gvsu-logo.png",
        title: "Grand Valley State University",
        description: (
            <>
                <p style={{ textIndent: "1rem" }}>
                    • Grand Valley's Computer Information Systems core classes includes, but not limited to: Computer Science 1 & 2, Visual Basic, Spreadsheets, Databases, Network Management, Internships, and more! Gained strong foundation in programming fundamentals, data structures, algorithms, and software engineering principles.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Information Systems elective classes includes, but not limited to: Advanced Databases, Web Application Programming, Software Development Tools, Systems Analysis & Design, and Mobile Application Development. Focused on practical applications of technology in business contexts.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Completed rigorous courses such as learning VBA, database normalization, transaction management, and database security. Gained hands-on experience with Oracle, MySQL, and Python.
                </p>
            </>
        ),
        name: "Computer Information Systems",
        date: "Aug 2023 - May 2025",
        button: "Visit School Website",
        link: "https://www.gvsu.edu/computing/information-systems-bs-43.htm"
    },
    {
        id: 3,
        schoolpic: edxbootcamp,
        title: "edX Coding Boot Camp",
        description: (
            <>
                <p style={{ textIndent: "1rem" }}>
                    • Completed an intensive coding bootcamp focused on full-stack MERN applications using MongoDB, Express.js, React.js, Node.js, and Next 14 for server-side rendering and API routes.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Developed responsive web applications using Bootstrap 5, Tailwind CSS, and Material UI, client-side fetching data using Axios and jQuery, implementing PWA features with Webpack and Workbox for offline functionality, and state management with Redux Toolkit and Context API.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Gained hands-on experience with cloud services (AWS EC2, S3 Bucket, Lambda), Docker containerization, and database management using MongoDB Atlas, MySQL with Sequelize ORM, and learned how to convert Restful APIs to GraphQL APIs.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Learned about TDD using Jest and React Testing Library, implemented CI/CD pipelines with GitHub Actions, and utilized agile methodologies with Git version control and project management tools such as Monday.com.
                </p>
            </>
        ),
        name: "Coding Bootcamp",
        date: "May 2022 - November 2022",
        button: "Visit School Website",
        link: "https://bootcamp.msu.edu/"
    },
    {
        id: 2,
        schoolpic: "https://s3.us-west-2.amazonaws.com/ycbm.production.upload.files/ycbm/dWsRhFGeavRSpuRzgxr3/images/egcc_new_top_logo.png",
        title: "Eastern Gateway Community College",
        description: (
            <>
                <p style={{ textIndent: "1rem" }}>
                    • Learned how to work in & lead teams, understand & prepare business & marketing plans, & identify the four functions of management (Planning, organizing, leading, and controlling).
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Courses learned include, but not limited to, Financial Accounting, Principles of Management, Micro and MacroEconomics, Securities and Investments, Business Communications, Managerial Accounting, and more!
                </p>
            </>
        ),
        name: "Business Management",
        date: "March 2020 - May 2022",
        button: "Visit School Website",
        link: "https://egcc.edu/"
    },
];

const Education = () => {
    return (
        <section className="pb-4">
            <div className="flex justify-center flex-wrap ">
                {educationData.map((education, id) => (
                    <EducationItem education={education} key={id} />
                ))}
            </div>
        </section>
    );
};

export default Education;