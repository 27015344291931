import React, { useContext, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com"; // Import emailjs-com
import "react-toastify/dist/ReactToastify.css";
import { ThemeContext } from "../../../ThemeProvider/ThemeProvider";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";

const ContactForm = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
    const textColor = isDarkMode ? "text-white" : "text-gray-500";

    const form = useRef();
    const [formData, setFormData] = useState({
        user_name: "",
        user_email: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_bmblsfc",
                "template_ppnq07u",
                form.current,
                "V29YcDp9rNU1dmhVP" // Replace with your EmailJS User ID
            )
            .then(
                (result) => {
                    console.log(result.text);
                    toast.success("Email sent successfully");
                    // reset the form
                    setFormData({
                        user_name: "",
                        user_email: "",
                        subject: "",
                        message: "",
                    });
                },
                (error) => {
                    console.error(error.text);
                    toast.error("Failed to send email");
                }
            );
    };

    return (
        <section className="relative py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                        <form
                            ref={form}
                            onSubmit={sendEmail}
                            className={`p-8 ${isDarkMode ? "bg-gray-900" : "bg-white"} rounded-xl shadow`}
                        >
                            <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? "text-gray-100" : "text-gray-800"}`}>
                                Get in Touch
                            </h3>
                            <div className="mb-4">
                                <label htmlFor="user_name" className={`block text-sm font-medium mb-2 ${isDarkMode ? "text-gray-300" : "text-gray-700"}`}>
                                    Your Name
                                </label>
                                <input
                                    className={`w-full border rounded-lg py-3 px-4 text-sm ${isDarkMode ? "bg-gray-800 border-gray-700 text-white" : "bg-gray-100 border-gray-300 text-gray-800"}`}
                                    type="text"
                                    name="user_name"
                                    value={formData.user_name}
                                    onChange={handleChange}
                                    placeholder="Your Name"
                                    required
                                    aria-label="Your Name"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="user_email" className={`block text-sm font-medium mb-2 ${isDarkMode ? "text-gray-300" : "text-gray-700"}`}>
                                    Your Email
                                </label>
                                <input
                                    className={`w-full border rounded-lg py-3 px-4 text-sm ${isDarkMode ? "bg-gray-800 border-gray-700 text-white" : "bg-gray-100 border-gray-300 text-gray-800"}`}
                                    type="email"
                                    name="user_email"
                                    value={formData.user_email}
                                    onChange={handleChange}
                                    placeholder="email@mail.com"
                                    required
                                    aria-label="Your Email"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="subject" className={`block text-sm font-medium mb-2 ${isDarkMode ? "text-gray-300" : "text-gray-700"}`}>
                                    Subject
                                </label>
                                <input
                                    className={`w-full border rounded-lg py-3 px-4 text-sm ${isDarkMode ? "bg-gray-800 border-gray-700 text-white" : "bg-gray-100 border-gray-300 text-gray-800"}`}
                                    type="text"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    placeholder="Subject"
                                    required
                                    aria-label="Subject"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="message" className={`block text-sm font-medium mb-2 ${isDarkMode ? "text-gray-300" : "text-gray-700"}`}>
                                    Your Message
                                </label>
                                <textarea
                                    className={`w-full border rounded-lg py-3 px-4 text-sm ${isDarkMode ? "bg-gray-800 border-gray-700 text-white" : "bg-gray-100 border-gray-300 text-gray-800"}`}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder="Leave a message for me to read"
                                    required
                                    rows="5"
                                    aria-label="Your Message"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 rounded-lg transition duration-300"
                            >
                                Send Message
                            </button>
                        </form>
                    </div>
                    <div className="w-full lg:w-1/2 px-4">
                        <div className={`p-8 ${isDarkMode ? "bg-gray-900" : "bg-white"} rounded-xl shadow`}>
                            <h3 className={`text-2xl font-semibold mb-6 ${isDarkMode ? "text-gray-100" : "text-gray-800"}`}>
                                Contact Information
                            </h3>
                            <ul className="space-y-4">
                                <li className="flex items-center">
                                    <FaEnvelope className="text-blue-500 mr-3" />
                                    <span className={`${isDarkMode ? "text-gray-300" : "text-gray-700"}`}>usais@mail.gvsu.edu</span>
                                </li>
                                <li className="flex items-center">
                                    <FaPhone className="text-blue-500 mr-3" />
                                    <span className={`${isDarkMode ? "text-gray-300" : "text-gray-700"}`}>+1 (616) 309-4043</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section>
    );
};

export default ContactForm;
