import ThemeProvider, { ThemeContext } from './ThemeProvider/ThemeProvider';
import Banner from './components/sections/Banner/Banner';
import Card from './components/sections/Card/Card';
import Navbar from './components/sections/Navbar/Navbar';
import Footer from "./components/sections/Footer/Footer";
import Preloader from "./Preloader";
import shape1 from "./images/shapes/hero-shape-1.png";
import shape2 from "./images/shapes/hero-shape-2.png";
import shape3 from "./images/shapes/token-sale-shape-svg.png";
import shape4 from "./images/shapes/shape4.png";
import shape5 from "./images/shapes/footer-shape-2-svg.png";
import shape6 from "./images/shapes/footer-shape-3-svg.png";
import shape7 from "./images/shapes/hero-shape-23-svg.png";
import shape8 from "./images/shapes/timeline.png";
import shape9 from "./images/shapes/Vector-Purple-PNG-Clipart.png"
import React, { useContext, useState, useEffect } from "react";

function App() {
    const { isDarkMode } = useContext(ThemeContext);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        const preloader = document.querySelector('.preloader');
        if (preloader) {
            preloader.style.setProperty('--preloader-background-color', isDarkMode ? '#010615' : '#fff');

            preloader.style.setProperty('--preloader-icon-background', isDarkMode ? '#fff704' : '#0090ff');
        }

    }, [isDarkMode]);



    return (
        <main
            className={`min-h-screen relative ${isDarkMode ? "bg-custom-blue" : "bg-gray-50"} pb-10`}>
            <Preloader />


            <div
                className="absolute top-0 left-0 right-0 bottom-0 bg-contain bg-no-repeat bg-left-top"
                style={{
                    backgroundImage: `url(${shape1}), url(${shape2}), url(${shape3}), url(${shape4}), url(${shape5}), url(${shape6}), url(${shape7}), url(${shape8})`,
                    backgroundPosition: "0 384px, 100% 384px, 100% 1000px, 0 800px, 0 1700px, 100% 2000px, 0 2580px, 0 1000px",
                    backgroundSize: "auto",
                    opacity: "0.6"
                }}>
            </div>
            <Banner />
            <div className="container sm:px-4">
                <div className="flex flex-wrap px-4">
                    <div className="w-full xl:w-1/3">
                        <Card />
                    </div>
                    <div className="w-full xl:w-2/3">
                        <Navbar />
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    );
}

function AppWrapper() {
    return (
        <ThemeProvider>
            <App />
        </ThemeProvider>
    );
}

export default AppWrapper;