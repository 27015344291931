import React, { useContext } from "react";
import Skills from "./Skills/Skills";
import { ThemeContext } from "../../../ThemeProvider/ThemeProvider";
import { FaPlaneDeparture, FaKeycdn, FaGitAlt } from "react-icons/fa";
import { SiWebflow, SiRoblox, SiNestjs, SiMysql, SiExpress } from "react-icons/si";
import { GiPegasus } from "react-icons/gi";
import { BsFillMicFill } from "react-icons/bs";



const About = () => {
  const { isDarkMode } = useContext(ThemeContext);

  const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
  const textColor = isDarkMode ? "text-gray-400" : "text-gray-500";

  const technologies = [
    {
      name: "PlanetScale",
      url: "https://planetscale.com",
      icon: <SiMysql className="text-pink-600" />,
      description: "Serverless MySQL Platform"
    },
    {
      name: "Git",
      url: "https://git-scm.com",
      icon: <FaGitAlt className="text-yellow-600" />,
      description: "Version Control"
    },
    {
      name: "KeystoneJS",
      url: "https://www.keystonejs.com", 
      icon: <FaKeycdn className="text-blue-600" />,
      description: "Headless CMS & GraphQL"
    },
    {
      name: "ExpressJS",
      url: "https://expressjs.com",
      icon: <SiExpress className="text-gray-600" />,
      description: "Node.js Framework"
    },
    {
      name: "Pegasystem",
      url: "https://www.pega.com",
      icon: <GiPegasus className="text-blue-700" />,
      description: "Low-Code Platform"
    },
    {
      name: "Webflow",
      url: "https://webflow.com",
      icon: <SiWebflow className="text-blue-500" />,
      description: "Visual Web Development"
    },
    {
      name: "Roblox Studio",
      url: "https://developer.roblox.com/en-us/studio",
      icon: <SiRoblox className="text-red-500" />,
      description: "Game Development"
    },
    {
      name: "Voicemaker.in",
      url: "https://voicemaker.in",
      icon: <BsFillMicFill className="text-purple-600" />,
      description: "AI Text To Speech"
    },
  ];

  return (
    <section className="relative py-8">
      <div className="flex flex-wrap md:px-4">
        <div className="w-full">
          <div className="md:mx-4">
            <h3 className={`text-2xl font-medium mb-4 ${boldColor}`}>
              About Me
            </h3>
            <div className="text-gray-400 mb-8 text-md">
              <p className="mb-4 leading-relaxed">
                I am a full stack web developer.
                My background as a social media content creator has provided me valuable transferable skills such as Digital Marketing, Search Engine Optimization, Click Funnels, and Brand Partnerships.
                My experience has taught me the importance of user experience and attention to detail which I use to problem-solve and create value.
              </p>
              <p className="leading-relaxed">
                Since 2015, I have owned and managed multiple successful {" "}
                <span className="text-red-500 font-medium">
                  YouTube {" "}
                </span>
                channels creating content primarily on the Roblox and Fortnite platforms with large followings over the years, demonstrating my skills in online content creation and audience engagement. 
                In 2023, I decided to change careers into the tech industry to pursue a career in software engineering.
              </p>
            </div>
          </div>

          <section className="relative pb-4">
            <div className="flex flex-wrap md:px-4">
              <div className="w-full">
                <h3 className={`text-2xl font-medium mb-6 ${boldColor}`}>
                  My Most Used Technologies & Tools
                </h3>
                <div className={`${isDarkMode ? "bg-gray-900" : "bg-white"} p-6 rounded-xl shadow`}>
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {technologies.map((tech, index) => (
                      <a
                        key={index}
                        href={tech.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${isDarkMode ? "bg-gray-800 hover:bg-gray-700" : "bg-gray-50 hover:bg-gray-100"} 
                          p-4 rounded-lg transition-all duration-300 transform hover:-translate-y-1 hover:shadow-md`}
                      >
                        <div className="flex flex-col items-center text-center">
                          <div className={`text-3xl mb-3 ${isDarkMode ? "text-blue-400" : "text-blue-500"}`}>
                            {tech.icon}
                          </div>
                          <h4 className={`font-medium mb-1 ${boldColor}`}>{tech.name}</h4>
                          <p className={`text-sm ${textColor}`}>{tech.description}</p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Skills />

        </div>
      </div>
    </section>
    
  );
};

export default About;