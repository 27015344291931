import React, { useContext } from "react";
import {ThemeContext} from "../../../../ThemeProvider/ThemeProvider";

const ExperienceItem = (props) => {
    const { isDarkMode } = useContext(ThemeContext);
    const { logo, title, description, name, date } = props.experience;

    return (
        <div className="w-full mb-6">
            <div className={`${isDarkMode ? "bg-gray-900" : "bg-white"} 
                md:mx-4 p-4 shadow rounded-xl hover:shadow-xl
                transform transition-all duration-300 hover:-translate-y-1`}>
                <div className="flex flex-col md:flex-row items-start gap-4">
                    {/* Company Logo */}
                    <div className="flex-shrink-0">
                        <div className="w-16 h-16 rounded-full overflow-hidden
                            ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} p-2">
                            <img src={logo} alt={title} 
                                className="w-full h-full object-contain" />
                        </div>
                    </div>
                    
                    {/* Content */}
                    <div className="flex-grow">
                        <div className="flex flex-wrap justify-between items-center mb-2">
                            <div>
                                <h3 className={`text-lg font-semibold ${isDarkMode ? "text-gray-100" : "text-gray-800"}`}>
                                    {title}
                                </h3>
                                <div className={`text-sm ${isDarkMode ? "text-blue-400" : "text-blue-500"}`}>
                                    {date}
                                </div>
                            </div>
                            <span className={`px-3 py-1 rounded-full text-sm font-medium
                                ${isDarkMode ? "bg-gray-800 text-blue-400" : "bg-blue-100 text-blue-600"}`}>
                                {name}
                            </span>
                        </div>
                        
                        <div className={`text-sm space-y-2
                            ${isDarkMode ? "text-gray-400" : "text-gray-600"}`}>
                            {description}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExperienceItem;