import React, { useContext } from "react";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";
import KnowledgeItems from "./KnowledgeItems";
import { 
    FaHtml5, FaNodeJs, FaReact, FaPhp, FaGitAlt, FaDatabase,
    FaPython, FaDocker, FaBootstrap, FaCode, FaServer, FaYoutube,
    FaTools, FaVideo
} from "react-icons/fa";
import { 
    SiTailwindcss, SiJquery, SiNextdotjs, SiMongodb, 
    SiFirebase, SiGraphql, SiPostgresql, SiAdobephotoshop,
    SiMysql, SiLua, SiVegas
} from "react-icons/si";
import { BsCodeSlash, BsGraphUp, BsMegaphone, BsPersonVcard, BsTerminal } from "react-icons/bs";
import { DiJavascript, DiDatabase } from "react-icons/di";
import { TbApi } from "react-icons/tb";

const categories = {
    "Frontend Development": [
        { id: 1, title: "HTML, CSS, JavaScript", icon: <FaCode className="text-blue-400" /> },
        { id: 2, title: "React.js | Next.js", icon: <SiNextdotjs className="text-black dark:text-white" /> },
        { id: 3, title: "Tailwind", icon: <SiTailwindcss className="text-blue-500" /> },
        { id: 4, title: "jQuery", icon: <SiJquery className="text-blue-500" /> },
    ],
    "Backend Development": [
        { id: 5, title: "Node.js | Express.js", icon: <FaNodeJs className="text-green-600" /> },
        { id: 6, title: "PHP | Phalcon", icon: <FaPhp className="text-purple-500" /> },
        { id: 7, title: "Python | Flask", icon: <FaPython className="text-yellow-500" /> },
        { id: 8, title: "Lua | Lapis", icon: <SiLua className="text-blue-600" /> },
        { id: 9, title: "COBOL", icon: <BsTerminal className="text-blue-800" /> },
    ],
    "Database & APIs": [
        { id: 10, title: "MySQL | PostgreSQL", icon: <SiMysql className="text-blue-500" /> },
        { id: 11, title: "MongoDB", icon: <SiMongodb className="text-green-500" /> },
        { id: 12, title: "Firebase", icon: <SiFirebase className="text-yellow-500" /> },
        { id: 13, title: "REST | GraphQL", icon: <TbApi className="text-pink-500" /> },
    ],
    "Tools & Creative": [
        { id: 14, title: "Git | GitHub", icon: <FaGitAlt className="text-yellow-500" /> },
        { id: 15, title: "Adobe Photoshop", icon: <SiAdobephotoshop className="text-blue-800" /> },
        { id: 16, title: "Sony Vegas Pro", icon: <SiVegas className="text-blue-600" /> },
    ],
    "Digital Marketing": [
        { id: 17, title: "Social Media Growth", icon: <BsGraphUp className="text-green-500" /> },
        { id: 18, title: "Search Ranking Strategies", icon: <BsCodeSlash className="text-blue-500" /> },
        { id: 19, title: "Content Creation", icon: <BsMegaphone className="text-purple-500" /> },
        { id: 20, title: "Personal Branding", icon: <BsPersonVcard className="text-indigo-500" /> },
    ],
};

const Knowledges = () => {
    const { isDarkMode } = useContext(ThemeContext);

    return (
        <section className="pb-8">
            <div className={`${isDarkMode ? "bg-gray-900/50" : "bg-gray-50"} rounded-xl p-6 shadow-sm`}>
                {Object.entries(categories).map(([category, items]) => (
                    <div key={category} className="mb-8 last:mb-0">
                        <h3 className={`text-lg font-semibold mb-4 px-2
                            ${isDarkMode ? "text-gray-100" : "text-gray-800"}`}>
                            {category}
                        </h3>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                            {items.map((knowledge) => (
                                <KnowledgeItems knowledge={knowledge} key={knowledge.id} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Knowledges;