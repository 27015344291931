import React, { useContext } from "react";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";

const Summary = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const bgColor = isDarkMode ? "bg-gray-800" : "bg-gray-100";
    const textColor = isDarkMode ? "text-gray-200" : "text-gray-700";
    const alertBgColor = isDarkMode ? "bg-red-900" : "bg-red-100";
    const alertTextColor = isDarkMode ? "text-red-200" : "text-red-700";
    const alertBorderColor = isDarkMode ? "border-red-800" : "border-red-200";

    return (
        <section className={`flex flex-wrap md:px-4 py-12 ${bgColor}`}>
            <div className="w-full max-w-3xl mx-auto">
                <div className="md:mx-4 text-center">
                    <div className={`${alertBgColor} ${alertTextColor} mb-8 p-6 border-2 ${alertBorderColor} rounded-xl shadow-lg hover:shadow-xl`}>
                        <div className="flex items-center justify-center">
                            <svg className="w-8 h-8 mr-3 animate-pulse" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                            <p className="text-xl font-bold">
                                This blog section has been archived.
                            </p>
                        </div>
                    </div>
                    <p className={`text-lg ${textColor} leading-relaxed max-w-2xl mx-auto`}>
                        Previously, I shared my interests with the world, including progress on projects, personal stories, and more.
                        I no longer have the time and energy to maintain this blog page, sorry.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Summary;