import React, { useContext } from "react";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";
import { FaCode, FaYoutube, FaPencilAlt, FaLaptopCode, FaUsers } from "react-icons/fa";
import { BsGraphUp } from "react-icons/bs";

const Summary = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const highlights = [
        {
            icon: <FaLaptopCode />,
            title: "Full Stack Development",
            description: "MERN stack specialist with modern web technologies"
        },
        {
            icon: <FaYoutube />,
            title: "Content Creation",
            description: "3M+ subscribers, 500K+ monthly views"
        },
        {
            icon: <BsGraphUp />,
            title: "Digital Marketing",
            description: "SEO, analytics, and audience growth"
        },
        {
            icon: <FaUsers />,
            title: "Community Building",
            description: "500K+ active community members"
        },
        {
            icon: <FaCode />,
            title: "Technical Skills",
            description: "React, Node.js, Next.js, MySQL"
        },
        {
            icon: <FaPencilAlt />,
            title: "Content Strategy",
            description: "Brand partnerships & monetization"
        }
    ];

    return (
        <section className="pb-4 flex flex-wrap">
            <div className="w-full">
                <div className={`p-6 rounded-xl shadow ${isDarkMode ? "bg-gray-900" : "bg-white"}`}>
                    {/* Professional Summary */}
                    <div className="mb-8">
                        <h4 className={`text-xl font-semibold mb-4 ${isDarkMode ? "text-gray-100" : "text-gray-800"}`}>
                            Professional Summary
                        </h4>
                        <p className={`text-base leading-relaxed ${isDarkMode ? "text-gray-300" : "text-gray-600"}`}>
                            Software engineer with a strong background in content creation and digital marketing. 
                            Combining problem-solving skills in web development with years of experience in engaging online audiences.
                        </p>
                    </div>
                    
                    {/* Key Highlights Grid */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {highlights.map((item, index) => (
                            <div 
                                key={index} 
                                className={`p-4 rounded-xl ${isDarkMode ? "bg-gray-800" : "bg-gray-50"} 
                                    transform transition-all duration-300 hover:-translate-y-1
                                    border ${isDarkMode ? "border-gray-700" : "border-gray-200"}`}
                            >
                                <div className="flex items-start space-x-3">
                                    <div className={`text-2xl ${isDarkMode ? "text-blue-400" : "text-blue-500"}`}>
                                        {item.icon}
                                    </div>
                                    <div>
                                        <h4 className={`text-md font-semibold mb-1 ${isDarkMode ? "text-gray-100" : "text-gray-800"}`}>
                                            {item.title}
                                        </h4>
                                        <p className={`text-sm ${isDarkMode ? "text-gray-400" : "text-gray-600"}`}>
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Summary;