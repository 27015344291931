import React, { useContext } from "react";
import Summary from "./Summary/Summary";
import Blog from "./Blog/Blog";
import {ThemeContext} from "../../../ThemeProvider/ThemeProvider";
const About = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
    return (
        <section className="relative py-8">
            <div className="flex flex-wrap md:px-4">
                <div className="w-full">

                    <div className="md:mx-4">
                        <h3 className={`${boldColor} text-2xl font-medium mb-4`}>My Blogs</h3>
                    </div>
                    <Summary />
                </div>
            </div>
        </section>
    );
};

export default About;