import React from "react";
import banner from "../../../images/HTML_Blog-scaled.jpeg";

const Banner = () => {
    return (
        <div className="h-96 w-full">
            <img className="flex w-full h-full object-cover object-center"
                 src={banner}
                 alt="banner"
            />
        </div>
    );
};

export default Banner;