import React, { useContext } from "react";
import Webdev from "./Webdev/Webdev";
import GFX from "./GFX/GFX";
import {ThemeContext} from "../../../ThemeProvider/ThemeProvider";
// I should work on this...
const About = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
    return (
        <section className="relative py-8">
            <div className="flex flex-wrap md:px-4">
                <div className="w-full">
                    <div className="md:mx-4">
                        <p className={`${isDarkMode ? "text-gray-300" : "text-gray-800"} italic text-xs mb-2`}>*Updated September 19 2024</p>
                        <h3 className={`${boldColor} text-2xl font-medium mb-4`}> Coding Projects </h3>
                        <div className="text-gray-400 mb-3">
                            <p className="m-1 text-md">
                                My coding projects I had here before were work during my time at a{" "}
                                <a href="https://bootcamp.msu.edu/" target="_blank" rel="noopener noreferrer"
                                   className="text-blue-500 underline"> Coding Bootcamp.</a>
                                {" "}Over time, I will have newer and original projects to showcase for potential
                                employers or people who want to see what I'm working on.
                            </p>
                            <p className="m-1 text-md">
                                These projects are just some of my best work. Please see my{" "}
                                <a href="https://www.github.com/singharaj-usai" target="_blank" rel="noopener noreferrer"
                                   className="text-blue-500 underline"> GitHub</a>
                                {" "}profile to view more of my projects that are not shown here.
                            </p>
                        </div>
                    </div>
                    <Webdev/>
                    <div className="md:mx-4">
                        <h3 className={`${boldColor} text-2xl font-medium mb-4`}> YouTube Thumbnail Designs </h3>
                        <div className="text-gray-400 mb-3">
                            <p className="m-1 text-md">
                                In my spare time, I create thumbnails for YouTube videos. These are some examples of
                                thumbnails I created for my channels over the years, you can view more of my work
                                on my{" "}
                                <a href="https://deviantart.com/realmrbobbilly" target="_blank" rel="noopener noreferrer"
                                   className="text-blue-500 underline"> DeviantArt portfolio.</a>

                            </p>
                        </div>
                    </div>
                    <GFX/>
                </div>
            </div>
        </section>
    );
};

export default About;