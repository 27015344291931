import React, { useContext } from "react";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";

const SkillsItem = (props) => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";

    const { icon, title, description } = props.service;
    return (
        <div className="w-full lg:w-1/3 sm:w-1/2 mb-6 md:px-4">
            <div className={`${isDarkMode ? "bg-gray-900" : "bg-white"} 
                p-6 shadow rounded-lg group 
                hover:shadow-lg transition-shadow duration-300
                overflow-hidden flex flex-col h-full
                ${isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-50'}`}>
                <div className={`w-12 h-12 flex items-center justify-center rounded-full text-2xl mb-4 ${isDarkMode ? "bg-transparent" : "bg-blue-100"} text-blue-500 transition duration-200 group-hover:bg-blue-500 group-hover:text-white`}>
                    {icon}
                </div>
                <div className="flex-grow">
                    <h3 className={`text-lg font-semibold ${boldColor} mb-2`}>{title}</h3>
                    <p className="text-sm text-gray-400 leading-relaxed">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default SkillsItem;